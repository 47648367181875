import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import { Link, navigate } from "gatsby";
import TextField from "../components/form/TextField";
import { useFormik } from "formik";
import { getFormError } from "../utils/form";
import Button from "../components/form/button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  login_form_initial_values,
  login_validation_schema,
} from "../utils/user";
import { login } from "../features/userSlice";
import useToastify from "../hooks/ui/useToastify";

const LoginPage = () => {
  const { user } = useSelector((state) => state.auth);
  const { toastMessage } = useToastify();
  const dispatch = useDispatch();
  const validateCustomer = (values) => {
    dispatch(login({ values: values, toastMessage: toastMessage }));
  };

  const formik = useFormik({
    initialValues: login_form_initial_values,
    validationSchema: login_validation_schema,
    onSubmit: (values) => {
      validateCustomer(values);
    },
  });

  useEffect(() => {
    if (user?.accessToken) {
      navigate("/");
    }
  }, [user]);

  return (
    <Layout>
      <div className="page-wrapper">
        <div className="page-heading-section text-center page-block">
          <h1 className="page-title">Sign In</h1>
        </div>
      </div>

      <div className="login-section">
        <div className="container">
          <div className="row flex vertical-middle flex-wrap">
            <div className="col login-form-block">
              <form onSubmit={formik.handleSubmit} className="login-form form">
                <div className="form-field">
                  <TextField
                    id="user-email"
                    label={"Email Address"}
                    labelFor={"Email Address"}
                    name={"email"}
                    error={getFormError(formik.errors, "email")}
                    type={"email"}
                    onChange={formik.handleChange}
                    touched={formik.touched["email"]}
                  />
                </div>

                <div className="form-field">
                  <TextField
                    id="user-password"
                    name={"password"}
                    label={"Password"}
                    htmlFor={"password"}
                    type={"password"}
                    onChange={formik.handleChange}
                    touched={formik.touched["password"]}
                    error={getFormError(formik.errors, "password")}
                  />
                </div>

                <div className="form-actions">
                  <Button type="submit" className="button">
                    Sign in
                  </Button>
                  <Link
                    className="forgot-password link-dark"
                    to="/reset-password"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </form>
            </div>
            <div className="col login-info-block">
              <div className="panel">
                <div className="panel-header">
                  <h2 className="panel-title">New Customer?</h2>
                </div>
                <div className="panel-body">
                  <p className="new-customer-intro">
                    Create an account with us and you'll be able to:
                  </p>

                  <ul className="new-customer-fact-list">
                    <li>Check out faster</li>
                    <li>Save multiple shipping addresses</li>
                    <li>Access your order history</li>
                    <li>Track new orders</li>
                    <li>Save items to your Wish List</li>
                  </ul>

                  <Link className="button" to="/create-account">
                    Create Account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;
